.homepage-sc {
  position:fixed;
  width: 100%;
  height:100%;
  left:0;
  top: 0;

  background: #fff;
  color: #000;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: .4s color linear;

  &:hover {
    cursor: pointer;
  }
}



.homepage-sc-ttl {
  position:relative;
  text-transform: lowercase;
  font-size:55px;
  font-weight:500;
  z-index:2;

  a {
    text-decoration: none;
    color:#000;
    transition: .4s color linear;
  }
}


.homepage-sc-cprt {
  position:absolute;
  bottom:20px;
  left:50px;
  right:50px;
  text-align:center;
  font-size: 11px;
  line-height: 16px;
  font-weight:600;
  z-index:2;
}

.homepage-sc-img {
  position:absolute;
  top:0;
  left:0;
  z-index:1;
  width:100%;
  height:100%;
  transition: .4s opacity linear;
  opacity:0;
  will-change:opacity;


  //span {
  //  position:relative;
  //  display:block;
  //  top: 0;
  //  left:0;
  //  width:100%;
  //  height:100%;
  //  background-position: center;
  //  background-repeat: no-repeat;
  //  background-size: cover;
  //  image-rendering: pixelated;
  //
  //}

  img {
    position: absolute;
    transition: 16s linear;
    will-change: transform, left;

  }
  img.i_pic_start_over {
    transform: scale(1.2) translate(0%,0);
    left: -20% !important;
  }

  img.i_pic_start_disabled {
    transition: none !important;
    transform: translateX(0%) scale(1) !important;
    left:0% !important;
  }
}

.homepage-sc.loaded {

    background: #000;
    color:#fff;

  .homepage-sc-ttl a {
    color: #fff;
  }
  .homepage-sc-img {
    opacity:1;
  }
}