
//body:before {
//  content:"";
//  background: #f00;
//  display:block;
//  position:absolute;
//  top:0;
//  left:0;
//  right:0;
//  height:158px;
//}
.single-post {
  .entry-content {
    text-align:center;
  }

  .entry-media {
    margin-bottom: 76px;

    &:nth-of-type(1) {
      margin-top: -22px; // this caters for the 22px cross that is pulled up with relative positioning
    }

    img {
      opacity: 0;
      transition: opacity .4s linear;
      will-change: opacity;
      max-width: 100%;
      height: auto;

      &.lazyloaded {
        opacity: 1;
      }
    }
  }
}