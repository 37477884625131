@import "common/variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/susy/sass/_susy.scss";
@import "../../bower_components/sassy-maps/sass/_sassy-maps.scss";
@import "../../bower_components/breakpoint-sass/stylesheets/_breakpoint.scss";
// endbower

@import "common/global";
//@import "components/buttons";
//@import "components/comments";
//@import "components/forms";
@import "components/grid";
@import "components/back";
@import "components/embed";


//@import "components/wp-classes";
@import "components/homepage";
@import "components/contact";



@import "layouts/header";
//@import "layouts/sidebar";
@import "layouts/footer";
//@import "layouts/pages";
@import "layouts/posts";
@import "layouts/post";



.progressjs-container {
  position:fixed;
  top:0;
  left:0;
  right:0;
  z-index:0;
}

.progressjs-progress {
  background: transparent;
  width:100%;

}
.progressjs-inner {
  background: #000;
  transition: .2s line
}
.progressjs-percent {
  font-size:0.1px;
  height:5px;
}

//@import "layouts/tinymce";

