

.contact-ttl {
  color: #777;
  text-transform: none;
  margin: .5em 0;

}


.contact-description {
  margin: .5em 0;
}

.contact-address {
  margin:  0 6em;
}

.contact-email {
  margin: .5em 0;
}


.contact-phone:before,
.contact-mobile:before,
.contact-email:before {
  font-size: .8em;
  display:inline-block;
  width: 1.4em;
}
.contact-phone:before {
  content: "T. ";
}

.contact-mobile:before {
  content: "M. ";

}

.contact-email:before {
  content: "E. ";

}


.contact .main {
  @include container(12);

  h2 {
    font-size: 1.3rem;
    margin-bottom: 0;
  }

  font-size:.9em;

  .page-header {
    display:none;
  }

  .contact-column {
    //float:left;
    //width: 30%;

    text-align:center;



    @include breakpoint(800px) {
      @include gallery(6);
    }

    .contact {
      margin-bottom: 1.5em;
      line-height:1.4em;
    }
  }
}
