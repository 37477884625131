.entry-content-asset {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}

.entry-content-asset iframe,
.entry-content-asset object,
.entry-content-asset embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}