.footer {

  @include container(1000px);
  padding: 4em 0;
  .nav {
    text-align:center;
    padding:0;
    margin:0;

     .menu-item {
       padding:0;
       margin:0;
       display:inline-block;

       a {
         color: #000;
         border-bottom: #000 1px solid;
         text-decoration: none;
         font-weight: 300;
         font-size: 25px;
         text-transform: uppercase;
         font-family:bebas_neueregular, san-serif;
       }
     }
  }
}