
header.banner {
  @include span(12);
  text-align: center;
  height:150px;


  display:flex;
  align-items: center;
  justify-content: center;

  .brand {
    display:inline-block;
    color:#000;
    font-weight:300;
    //font-family:sans-serif;
    font-size:30px;
    text-decoration:none;
    border-bottom: #000 1px solid;
    //margin-top: -60px;
    padding-bottom:1px;
    margin-bottom:35px;
    text-transform:none;
  }
}