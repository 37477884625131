.back {
  position:relative;
  display:block;
  width: 22px;
  height: 22px;
  margin: 0 auto;

  opacity: 0.8;
  transition: opacity .2s linear;
  will-change: opacity;

  span {
    display:none;
  }
}

.back-top {
  top:-48px;
}
.back:hover {
  opacity: 1;
}
.back:before, .back:after {
  position: absolute;
  left: 8px;
  content: ' ';
  height: 22px;
  width: 1px;
  background-color: #333;
}
.back:before {
  transform: rotate(45deg);
}
.back:after {
  transform: rotate(-45deg);
}