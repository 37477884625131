
@font-face {
  font-family: 'bebas_neueregular';
  src: url('../fonts/bebasneue-webfont.eot');
  src: url('../fonts/bebasneue-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/bebasneue-webfont.woff2') format('woff2'),
  url('../fonts/bebasneue-webfont.woff') format('woff'),
  url('../fonts/bebasneue-webfont.ttf') format('truetype'),
  url('../fonts/bebasneue-webfont.svg#bebas_neueregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight:200;
}


h1,h2,h3,h4,h5,h6 {
  font-family:bebas_neueregular;
  text-transform:uppercase;
  font-weight:200;
}

a {
  color:#000;
  text-decoration:none;
}
a:hover {
  text-decoration: underline;
}


