.blog {

  .type-post {

    position:relative;
    overflow:hidden;
    margin-bottom:gutter();


    .entry-title {
      margin:0;
      padding:0;

      a {
        position:absolute;
        z-index:2;
        left:0;
        top:0;
        right:0;
        bottom:0;

        vertical-align: middle;
        text-align:center;
        background: rgba(0,0,0,0.3);

        opacity:0;
        transition: opacity .3s linear;
        will-change: opacity;


        color:#fff;
        font-weight:300;
        text-decoration:none;
        font-size:18px;

        display:flex;
        align-items: center;
        justify-content: center;
      }

    }

    .entry-img{
      position:absolute;
      left:0;
      right:0;
      top:0;
      bottom:0;

      z-index:1;
      overflow: hidden;
      img {
        will-change: transform, opacity;
        opacity:0;
        transition: opacity .2s linear, transform 5s linear;
        &.lazyloaded {
          opacity:1;
        }
      }
    }

    img {
      display:block;
      max-width:100%;
      height: auto;
    }

    &.post-has-video {
      .entry-img .lazyloaded + .overlay {
        display:block;
        position:absolute;
        left:0;
        right:0;
        top:0;
        bottom:0;

        display:flex;
        align-items: center;
        justify-content: center;

        opacity:1;
        will-change:opacity;
        transition: opacity .2s linear;

        background: url(../images/arrow.svg) center no-repeat rgba(0,0,0,0.2);

        z-index:3;
      }

      &:hover {
        .entry-img img + .overlay {
          opacity:0;
        }
      }

    }


    &:hover {
      .entry-title {
        a {
          opacity: 1;
        }
      }
      .entry-img {
        img {
          transform: scale(1.25);
        }
      }
      .entry-has-video {
        opacity:0;
      }
    }
  }


  .type-post {
    @include span(12);

    @include breakpoint(401px) {
      @include gallery(6);
    }


    @include breakpoint(680px) {
      @include gallery(4);
    }


  }
}