.progressjs-inner {
    width: 0;
}
.progressjs-progress {
    z-index: 9999999;
}

/* blue theme, like iOS 7 progress bar */
.progressjs-theme-blue .progressjs-inner {
    height: 2px;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    background-color: #3498db;
}
.progressjs-theme-blue.progressjs-end {
    -webkit-transition: opacity 0.2s ease-out;
    -moz-transition: opacity 0.2s ease-out;
    -o-transition: opacity 0.2s ease-out;
    transition: opacity 0.2s ease-out;
    opacity: 0;
}
.progressjs-theme-blue .progressjs-percent {
    display: none;
}

/* blue theme with overlay layer, no percent bar */
.progressjs-theme-blueOverlay {
    background-color: white;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}
.progressjs-theme-blueOverlay .progressjs-inner {
    height: 100%;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    background-color: #3498db;
}
.progressjs-theme-blueOverlay.progressjs-end {
    opacity: 0 !important;
}
.progressjs-theme-blueOverlay .progressjs-percent {
    display: none;
}

/* blue theme with overlay layer, no percent bar */
.progressjs-theme-blueOverlay {
    background-color: white;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}
.progressjs-theme-blueOverlay .progressjs-inner {
    height: 100%;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    background-color: #3498db;
}
.progressjs-theme-blueOverlay.progressjs-end {
    opacity: 0 !important;
}
.progressjs-theme-blueOverlay .progressjs-percent {
    display: none;
}

/* Blue theme with border radius and overlay layer */
.progressjs-theme-blueOverlayRadius {
    background-color: white;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    border-radius: 5px;
}
.progressjs-theme-blueOverlayRadius .progressjs-inner {
    height: 100%;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    background-color: #3498db;
    border-radius: 5px;
}
.progressjs-theme-blueOverlayRadius.progressjs-end {
    opacity: 0 !important;
}
.progressjs-theme-blueOverlayRadius .progressjs-percent {
    display: none;
}

/* Blue theme with border radius and overlay layer */
.progressjs-theme-blueOverlayRadiusHalfOpacity {
    background-color: white;
    opacity: 0.5;
   -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    border-radius: 5px;
}
.progressjs-theme-blueOverlayRadiusHalfOpacity .progressjs-inner {
    height: 100%;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    background-color: #3498db;
    border-radius: 5px;
}
.progressjs-theme-blueOverlayRadiusHalfOpacity.progressjs-end {
    opacity: 0 !important;
}
.progressjs-theme-blueOverlayRadiusHalfOpacity .progressjs-percent {
    display: none;
}

/* Blue theme with border radius, overlay layer and percent bar */
.progressjs-theme-blueOverlayRadiusWithPercentBar {
    background-color: white;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    border-radius: 5px;
}
.progressjs-theme-blueOverlayRadiusWithPercentBar .progressjs-inner {
    height: 100%;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    background-color: #3498db;
    border-radius: 5px;
}
.progressjs-theme-blueOverlayRadiusWithPercentBar.progressjs-end {
    opacity: 0 !important;
}
.progressjs-theme-blueOverlayRadiusWithPercentBar .progressjs-percent {
    width: 70px;
    text-align: center;
    height: 40px;
    position: absolute;
    right: 50%;
    margin-right: -35px;
    top: 50%;
    margin-top: -20px;
    font-size: 30px;
    opacity: .5;
}

.progressjs-theme-blackRadiusInputs {
    height: 10px;
    border-radius: 10px;
    overflow: hidden;
}
.progressjs-theme-blackRadiusInputs .progressjs-inner {
    height: 2px;
    -webkit-transition: all 1s ease-out;
    -moz-transition: all 1s ease-out;
    -o-transition: all 1s ease-out;
    transition: all 1s ease-out;
    background-color: #34495e;
}
.progressjs-theme-blackRadiusInputs.progressjs-end {
    -webkit-transition: opacity 0.2s ease-out;
    -moz-transition: opacity 0.2s ease-out;
    -o-transition: opacity 0.2s ease-out;
    transition: opacity 0.2s ease-out;
    opacity: 0;
}
.progressjs-theme-blackRadiusInputs .progressjs-percent {
    display: none;
}